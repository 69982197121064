import React, { useRef } from 'react'
import ArticleShare from '../../ArticleShare/ArticleShare'
import * as ArticleStyles from '../article/Article.module.scss'
import * as LBBStyles from './LittleBlackBook.module.scss'
import Facebook from '../../../assets/facebook.svg'
import Instagram from '../../../assets/instagram.svg'
import Twitter from '../../../assets/twitter.svg'
import Breadcrumb from '../../organisms/breadcrumb/Breadcrumb'
import Favourites from '../../Favourites/Favourites'
import Slider from 'react-slick'
import '../../../../static/slick.min.css'
import '../../../../static/slick-theme.min.css'
import { LittleBlackBook as ILittleBackBook } from '../../../types'
import { StaticImage } from 'gatsby-plugin-image'
import { formatDate } from '../../../lib/utils'

const slickSettings = {
  dots: false,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  initialSlide: 0,
  centerMode: false
}

interface LBBProps {
  post: ILittleBackBook
}

const LittleBlackBook = ({ post }: LBBProps) => {
  const lbbSlider = useRef<Slider | null>(null)
  const {
    latLong,
    lbbOpeningHours,
    lbbReviewLink,
    lBBImages,
    lbbTel,
    lbbTwitter,
    lbbEmail,
    lbbAddress,
    lbbFacebook,
    lbbInstagram,
    lbbURL,
    lbbURLLabel
  } = post
  const primaryCategory = {
    title: post.primaryCategory ?? null,
    URI: post.primaryCategoryURI ?? null
  }

  const moveCarousel = (index: number) => {
    lbbSlider.current?.slickGoTo(index)
  }

  return (
    <article className={LBBStyles.LBBArticle}>
      <div className={ArticleStyles.Wrapper}>
        <div className={ArticleStyles.Intro}>
          <Breadcrumb
            postType={`littleBlackBook`}
            primaryCategory={primaryCategory}
          />
          <h1 dangerouslySetInnerHTML={{ __html: post.title ?? '' }} />
          <span dangerouslySetInnerHTML={{ __html: post.excerpt ?? '' }} />
        </div>
      </div>
      {lBBImages && (
        <div className={LBBStyles.Gallery}>
          <Slider {...slickSettings} ref={lbbSlider}>
            {lBBImages.map((image, index) => (
              <div key={index} className={LBBStyles.GalleryImage}>
                <img src={image?.image ?? ''} />
              </div>
            ))}
          </Slider>
          <div className={LBBStyles.Thumbnails}>
            {lBBImages.map((image, index) => (
              <div
                className={LBBStyles.Thumbnail}
                key={index}
                onClick={() => moveCarousel(index)}
              >
                {image?.image && <img src={image?.image} alt={``} />}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={ArticleStyles.Wrapper}>
        <div className={`${ArticleStyles.Main} ${LBBStyles.LBB_Content}`}>
          <div className={ArticleStyles.Meta}>
            <span>
              {lBBImages && 1 > 2 && (
                <span className={LBBStyles.GalleryLauncher}>
                  View full gallery
                </span>
              )}
              {post.dateGmt ? formatDate(post.dateGmt) : ''}
            </span>
            <span className={ArticleStyles.Date}></span>
            <Favourites showText postId={post.databaseId.toString()} />
          </div>
          <div className={ArticleStyles.Content}>
            <div
              className={LBBStyles.Content}
              dangerouslySetInnerHTML={{ __html: post.content ?? '' }}
            />
          </div>
          <hr />
          <div className={LBBStyles.AtAGlance}>
            <h3>At a glance</h3>
            {latLong && (
              <>
                <div className={LBBStyles.GoogleMap}>
                  <iframe
                    width="820"
                    height="480"
                    loading="lazy"
                    allowFullScreen
                    referrerPolicy="no-referrer-when-downgrade"
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDySDPxJPrZ-9x46IaAcvbBXTSyhY7bAxc&q=${latLong}`}
                  ></iframe>
                </div>
                <p>
                  Open in{' '}
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${latLong}`}
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    Google Maps
                  </a>
                </p>
              </>
            )}
            {lbbAddress && (
              <p>
                <strong>Address:</strong>
                <br /> {lbbAddress}
              </p>
            )}
            {lbbURL && (
              <p>
                <strong>Website:</strong>
                <br />{' '}
                <a href={lbbURL}>
                  {lbbURLLabel ?? lbbURL.replace('https://', '')}
                </a>
              </p>
            )}
            {lbbEmail && (
              <p>
                <strong>Email:</strong>
                <br />{' '}
                <a href={`mailto:${lbbEmail}`}>
                  {lbbEmail.replace('mailto:', '')}
                </a>
              </p>
            )}
            {lbbTel && (
              <p>
                <strong>Telephone:</strong>
                <br /> {lbbTel}
              </p>
            )}
            {lbbReviewLink && (
              <p>
                <strong>
                  <a href={lbbReviewLink}>Read our review</a>
                </strong>
              </p>
            )}
            {lbbOpeningHours && (
              <span dangerouslySetInnerHTML={{ __html: lbbOpeningHours }} />
            )}
            <p>
              Follow <strong>{post.title}</strong> on:
              <span className={LBBStyles.socialLinks}>
                {lbbFacebook && (
                  <a href={lbbFacebook}>
                    <img src={Facebook} alt={`Facebook`} />
                  </a>
                )}
                {lbbTwitter && (
                  <a href={lbbTwitter}>
                    <img src={Twitter} alt={`Twitter`} />
                  </a>
                )}
                {lbbInstagram && (
                  <a href={lbbInstagram}>
                    <img src={Instagram} alt={`Instagram`} />
                  </a>
                )}
              </span>
            </p>
          </div>
          <div className={LBBStyles.Badge}>
            <StaticImage
              src={`../../../../static/images/weekend-escapes/badge.png`}
              alt={`LBB Badge`}
            />
          </div>
          <hr />
          <div className={`${ArticleStyles.Share} ${ArticleStyles.ExtraSpace}`}>
            <span className={ArticleStyles.ShareText}>Share this story:</span>{' '}
            <span className={ArticleStyles.SocialWrap}>
              <ArticleShare postLink={post.link ?? ''} />
            </span>
          </div>
        </div>
      </div>
    </article>
  )
}

export default LittleBlackBook
