import React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import LBB from '../components/layoutComponents/littleBlackBook/LittleBlackBook'
import Hr from '../components/Hr/Hr'
import ThisMonth from '../components/ThisMonth/ThisMonth'
import TopBanner from '../components/Banners/TopBanner/TopBanner'
import PageMeta from '../components/PageMeta/PageMeta'
import type { ILittleBlackBookPostData } from '../graphql/queries/LittleBlackBookPost'
import { fixAmpersands } from '../lib/utils'
import Leaderboard from '../components/Banners/Leaderboard/Leaderboard'

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      littleBlackBook(id: $id) {
        content
        databaseId
        dateGmt
        excerpt(format: RENDERED)
        latLong
        lbbOpeningHours
        lbbReviewLink
        link
        seo {
          metaDesc
          title
        }
        title
        lBBImages {
          image
        }
        primaryCategory
        primaryCategoryURI
        lbbFacebook
        lbbTel
        lbbEmail
        lbbURL
        lbbURLLabel
        lbbAddress
        lbbInstagram
        lbbTwitter
      }
      generalSettings {
        title
        siteId
        egSettingTwitter
      }
      adStarCodes {
        id
        adslot
      }
      whatsOnPosts: posts(
        where: {
          categoryName: "this-month"
          orderby: { field: DATE, order: DESC }
        }
        first: 1
      ) {
        nodes {
          id
          title
          uri
          featuredImage {
            node {
              mediaItemUrl
              altText
              homepagePreviewImage: sourceUrl(size: HOMEPAGE_PREVIEW)
            }
          }
        }
      }
      mustSeePosts: posts(
        where: {
          categoryName: "must-sees"
          orderby: { field: DATE, order: DESC }
        }
        first: 1
      ) {
        nodes {
          id
          title
          uri
          featuredImage {
            node {
              mediaItemUrl
              altText
              homepagePreviewImage: sourceUrl(size: HOMEPAGE_PREVIEW)
            }
          }
        }
      }
    }
  }
`

interface LittleBlackBookPostProps {
  data: ILittleBlackBookPostData
}

const LittleBlackBookPost = ({
  data: {
    wpgraphql: {
      littleBlackBook,
      generalSettings,
      adStarCodes,
      whatsOnPosts,
      mustSeePosts
    }
  }
}: LittleBlackBookPostProps) => {
  const { title, siteId } = generalSettings

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: littleBlackBook.seo?.title ?? littleBlackBook.title,
          description: littleBlackBook.seo?.metaDesc ?? littleBlackBook.excerpt,
          url: littleBlackBook.link,
          image:
            littleBlackBook?.lBBImages && littleBlackBook?.lBBImages[0]
              ? littleBlackBook?.lBBImages[0].image
              : ''
        }}
      />
      <Header siteId={siteId} siteName={fixAmpersands(title ?? null)} />
      <TopBanner ads={adStarCodes} pageType={`subcategory`} />
      <Leaderboard adSlot={'TopLB'} sticky />
      <LBB post={littleBlackBook} />
      <Hr />
      <ThisMonth
        county={fixAmpersands(title ?? null)}
        whatsOn={whatsOnPosts.nodes[0]}
        mustsee={mustSeePosts.nodes[0]}
      />
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default LittleBlackBookPost
