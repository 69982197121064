import React from 'react'
import * as ArticleShareProps from './ArticleShare.module.scss'
import Facebook from '../../assets/facebook.svg'
import Twitter from '../../assets/twitter.svg'
import Email from '../../assets/email.svg'
import WhatsApp from '../../assets/whatsapp.svg'

interface ArticleShareProps {
  postLink: string
}

const ArticleShare = ({ postLink }: ArticleShareProps) => {
  const subDomain = postLink.split('.')[0].replace('https://', '')
  const directory = postLink.split('/')[3]

  const link = postLink
    .replace(`//${subDomain}.`, `//${directory}.`)
    .replace(`/${directory}/`, `/`)

  return (
    <ul className={ArticleShareProps.Wrapper}>
      <li>
        <a href={`https://www.facebook.com/sharer.php?u=${link}`}>
          <img src={Facebook} />
        </a>
      </li>
      <li>
        <a href={`https://twitter.com/intent/tweet?text=${link}`}>
          <img src={Twitter} />
        </a>
      </li>
      <li>
        <a
          href={`mailto:?subject=I wanted you to see this site&amp;body=${link}`}
        >
          <img src={Email} />
        </a>
      </li>
      <li>
        <a href={`whatsapp://send?text=${link}`}>
          <img src={WhatsApp} />
        </a>
      </li>
    </ul>
  )
}

export default ArticleShare
