// extracted by mini-css-extract-plugin
export var AtAGlance = "LittleBlackBook-module--AtAGlance--3394c";
export var Badge = "LittleBlackBook-module--Badge--5fb7e";
export var Content = "LittleBlackBook-module--Content--7262e";
export var Gallery = "LittleBlackBook-module--Gallery--dccab";
export var GalleryImage = "LittleBlackBook-module--GalleryImage--f7443";
export var GalleryLauncher = "LittleBlackBook-module--GalleryLauncher--94d9d";
export var GoogleMap = "LittleBlackBook-module--GoogleMap--0fd02";
export var LBBArticle = "LittleBlackBook-module--LBBArticle--d8e1b";
export var LBB_Content = "LittleBlackBook-module--LBB_Content--5f2dd";
export var Thumbnail = "LittleBlackBook-module--Thumbnail--ecab9";
export var Thumbnails = "LittleBlackBook-module--Thumbnails--c6d2c";
export var kerning = "LittleBlackBook-module--kerning--8199a";
export var socialLinks = "LittleBlackBook-module--socialLinks--7f8c5";