import React from 'react'
import { Link } from 'gatsby'
import Heading from '../Blocks/heading/Heading'
import * as ThisMonthStyles from './ThisMonth.module.scss'
import { Post } from '../../types'
import { StaticImage } from 'gatsby-plugin-image'

interface ThisMonthProps {
  county: string | null
  whatsOn: Post
  mustsee: Post
}

const ThisMonth = ({ county, whatsOn, mustsee }: ThisMonthProps) => {
  return (
    <div className={ThisMonthStyles.Base}>
      <div className={ThisMonthStyles.Wrapper}>
        <Heading>This month in {county}</Heading>
        <div className={ThisMonthStyles.Blocks}>
          {mustsee && (
            <div className={ThisMonthStyles.Block}>
              {mustsee?.featuredImage?.node && (
                <img
                  src={mustsee.featuredImage.node.homepagePreviewImage}
                  alt={mustsee.title || ''}
                />
              )}
              <Link to={mustsee.uri || ''}>
                <span className={ThisMonthStyles.Text}>Must Sees</span>
              </Link>
            </div>
          )}
          <div className={ThisMonthStyles.Block}>
            <StaticImage
              src={`../../../static/images/weekend-escapes/spotlightImage.jpg`}
              alt={`Experiences`}
            />
            <a
              href={`https://muddystilettos.co.uk/weekend-escapes/search/experiences`}
            >
              <span className={ThisMonthStyles.Text}>Experiences</span>
            </a>
          </div>
          {whatsOn && (
            <div className={ThisMonthStyles.Block}>
              {whatsOn?.featuredImage?.node && (
                <img
                  src={whatsOn.featuredImage.node.homepagePreviewImage}
                  alt={whatsOn.title || ''}
                />
              )}
              <Link to={whatsOn.uri || ''}>
                <span className={ThisMonthStyles.Text}>What&apos;s on</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ThisMonth
