// extracted by mini-css-extract-plugin
export var AtAGlance = "Article-module--AtAGlance--15fc0";
export var BPTLMap = "Article-module--BPTLMap--dd8bb";
export var BPTLWrapper = "Article-module--BPTLWrapper--95bbc";
export var BPTLlogo = "Article-module--BPTLlogo--d1fbe";
export var BPTLlogoImage = "Article-module--BPTLlogoImage--3cdc7";
export var Badge = "Article-module--Badge--442a2";
export var Badges = "Article-module--Badges--f5a3f";
export var Base = "Article-module--Base--657da";
export var ButtonRow = "Article-module--ButtonRow--d8cc1";
export var CommentBlock = "Article-module--CommentBlock--03ac9";
export var CommentDate = "Article-module--CommentDate--1dc76";
export var CommentForm = "Article-module--CommentForm--bbd33";
export var CommentImg = "Article-module--CommentImg--b4189";
export var CommentText = "Article-module--CommentText--364cc";
export var Comments = "Article-module--Comments--13947";
export var CommentsTag = "Article-module--CommentsTag--99030";
export var Content = "Article-module--Content--27520";
export var ContentP = "Article-module--ContentP--5f7fc";
export var Date = "Article-module--Date--deb58";
export var ExpiredTreat = "Article-module--ExpiredTreat--09762";
export var ExtraSpace = "Article-module--ExtraSpace--33aaa";
export var FeaturedImage = "Article-module--FeaturedImage--3d197";
export var FormRow = "Article-module--FormRow--540d1";
export var FormTitle = "Article-module--FormTitle--6c476";
export var FullWidth = "Article-module--FullWidth--23b0e";
export var GoogleMap = "Article-module--GoogleMap--44ae4";
export var Intro = "Article-module--Intro--074a5";
export var IntroSpacer = "Article-module--IntroSpacer--cadda";
export var Main = "Article-module--Main--63843";
export var Meta = "Article-module--Meta--84deb";
export var SchoolDetails = "Article-module--SchoolDetails--b23d0";
export var SchoolReviewBlock = "Article-module--SchoolReviewBlock--ae523";
export var SchoolReviewCol = "Article-module--SchoolReviewCol--c9e09";
export var SchoolReviewColParagraph = "Article-module--SchoolReviewColParagraph--13b80";
export var SchoolSocialLinks = "Article-module--SchoolSocialLinks--94d8b";
export var Share = "Article-module--Share--83073";
export var ShareText = "Article-module--ShareText--5d108";
export var SocialWrap = "Article-module--SocialWrap--bc150";
export var SponsoredBy = "Article-module--SponsoredBy--d5b68";
export var TurquoiseImage = "Article-module--TurquoiseImage--91330";
export var TurquoiseSponsor = "Article-module--TurquoiseSponsor--7a011";
export var Wrapper = "Article-module--Wrapper--3e303";
export var isResized = "Article-module--is-resized--3f838";
export var wpCaption = "Article-module--wp-caption--0257e";